import React, { FC } from 'react';
import styled from 'styled-components';
import { colors, fonts, spacings } from 'utils/styles';

const View = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    // max-width: 200px;
    max-width: 270px;
`;

const TimePreset = styled.button<{ onClick?: () => void }>`
    box-sizing: border-box;
    outline: none;
    border: none;
    user-select: none;
    background: none;
    padding: ${spacings.nudge}px;
    margin: 0;

    font-family: ${fonts.primary};
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    color: ${colors.mono.medium};
    flex: 1 0 auto;

    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

    transition: color ease-in-out 0.2s;

    &:hover {
        // color: ${colors.mono.dark};
        opacity: ${({ onClick }) => onClick && 0.5};
    }

    &:focus {
        color: ${({ onClick }) => onClick && colors.primary};
    }
`;

const TimeSelect: FC<{
    presets?: Array<string>;
    onClick?: (index: number, value: string) => void;
    className?: string;
}> = ({ presets, onClick, className }) => {
    return (
        <View className={className}>
            {presets?.map((item, i) => (
                <TimePreset key={i} onClick={() => onClick && onClick(i, item)}>
                    {item}
                </TimePreset>
            ))}
            <TimePreset>MIN.</TimePreset>
        </View>
    );
};

export default TimeSelect;
