import * as React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    display: block;
`;

const HighVolume: React.FC<{
    iconColor?: string;
    className?: string;
}> = ({ iconColor = 'currentColor', className }) => {
    return (
        <SVG
            width="16"
            height="16"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M0 5.99998V12h4l5 5V.99998l-5 5H0zm13.5 3c0-1.77-1.02-3.29-2.5-4.03V13.02c1.48-.73 2.5-2.25 2.5-4.02002zm-2.5-8.77v2.06c2.89.86 5 3.54 5 6.71C16 12.17 13.89 14.85 11 15.71v2.06c4.01-.91 7-4.49 7-8.77002 0-4.28-2.99-7.86-7-8.77z"
                fill={iconColor}
            />
        </SVG>
    );
};

export default HighVolume;
