import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { hexToRgba } from 'utils/hexRgbConverter';
import { colors } from 'utils/styles';

const View = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
`;

const Progress = styled.div<{
    start?: number;
    goal?: number;
    duration?: number;
}>`
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.mono.dark};
    transform: translate(0, -${({ start }) => (start ? start * 100 : 0)}%);

    ${({ goal, duration }) =>
        goal &&
        css`
            transition: transform ${duration || 0}s linear;
            transform: translate(0, -${goal * 100}%);
        `}
`;

const Step = styled.div<{ isVisible?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-bottom: 2px dashed ${hexToRgba(colors.mono.medium, 0.5)};
    z-index: 1;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: translateY(-50%);

    transition: opacity 0.2s ease-in-out;
`;

const ProgressBackground: FC<{
    steps?: number;
    start?: number;
    goal?: number;
    duration?: number;
    showSteps?: boolean;
    enableTransition?: boolean;
}> = ({ steps = 1, start, goal, duration = 0, showSteps }) => {
    return (
        <View>
            {steps &&
                new Array(steps).fill('').map((item, i, arr) => {
                    if (i < arr.length - 1) {
                        return (
                            <Step
                                key={i}
                                isVisible={showSteps}
                                style={{
                                    top: `${((i + 1) * 100) / arr.length}%`,
                                }}
                            />
                        );
                    }
                })}
            <Progress start={start} goal={goal} duration={duration} />
        </View>
    );
};

export default ProgressBackground;
