import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colors, fonts, spacings } from 'utils/styles';
import Segments from 'components/base/icons/Segments';
import { hexToRgba } from 'utils/hexRgbConverter';

const View = styled.div`
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: auto;
    height: 37px;

    color: ${colors.mono.medium};
    padding: 0 ${spacings.nudge * 2}px;
    border: solid 2px transparent;
    border-radius: 6px;

    transition: background 0.1s ease-in-out, border 0.1s ease-in-out;

    &:focus-within {
        border: solid 2px ${colors.mono.light};
        background: ${hexToRgba(colors.mono.light, 0.25)};

        color: ${colors.mono.dark};
        opacity: 0.5;
    }
`;

const Icon = styled(Segments)`
    position: absolute;
    top: 50%;
    right: ${spacings.nudge * 2}px;
    bottom: 0;
    color: ${colors.mono.medium};
    pointer-events: none;

    transform: translateY(-50%);
`;

const StepList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: ${spacings.nudge * 5 - 3}px;
    padding-bottom: 2px;

    font-family: ${fonts.primary};
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    user-select: none;
`;

const StepItem = styled.span<{
    isActive?: boolean;
    isSelected?: boolean;
    interactable?: boolean;
}>`
    position: relative;
    padding: ${spacings.nudge * 2}px;
    color: ${({ isActive }) =>
        isActive ? colors.primary : colors.mono.medium};
    cursor: ${({ interactable }) => (interactable ? 'pointer' : 'default')};

    transition: color ease-in-out 0.2s;

    ${({ interactable }) =>
        interactable &&
        css`
            &:hover {
                // color: ${colors.mono.dark};
                opacity: 0.5;
            }
        `}

    &:after {
        content: ${({ isSelected }) => isSelected && '""'};
        position: absolute;
        left: 50%;
        bottom: 3px;
        width: 14px;
        border-bottom: 2px solid ${colors.mono.medium};
        transform: translateX(-50%);

        transition: opacity 0.1s ease-in-out;
    }
`;

export type StepPickerMode = 'edit' | 'show';

const StepPicker: FC<{
    mode?: StepPickerMode;
    maxSteps?: number;
    steps?: number;
    onChange?: (value?: number) => void;
    activeStep?: number;
    className?: string;
}> = ({
    mode = 'edit',
    maxSteps = 6,
    steps = 1,
    onChange,
    activeStep,
    className,
}) => {
    return (
        <View className={className}>
            <StepList>
                {steps &&
                    new Array(mode === 'show' ? steps : maxSteps)
                        .fill('')
                        .map((_, i) => (
                            <StepItem
                                key={i}
                                isActive={
                                    i + 1 === activeStep && mode === 'show'
                                }
                                isSelected={i + 1 === steps && mode === 'edit'}
                                interactable={mode === 'edit'}
                                onClick={() =>
                                    mode === 'edit' &&
                                    onChange &&
                                    onChange(i + 1)
                                }
                            >
                                {i + 1}
                            </StepItem>
                        ))}
            </StepList>
            <Icon />
        </View>
    );
};

export default StepPicker;
