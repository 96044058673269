import React, { FC } from 'react';
import styled from 'styled-components';

import { colors, mq, spacings } from 'utils/styles';
import Copy from 'components/typography/Copy';
import Heading, { HeadingTag } from 'components/typography/Heading';
import Image, { ImageProps } from 'components/blocks/Image';
// import CloseButton from 'components/buttons/CloseButton';

const View = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 720px;

    background: ${colors.white};
    padding: ${spacings.nudge * 2}px ${spacings.nudge * 2}px;
    padding-top: ${spacings.spacer * 2}px;
    border: 1px solid transparent;
    border-radius: 6px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);

    @media ${mq.semilarge} {
        flex-direction: row;
        padding-top: ${spacings.spacer * 2.5}px;
        padding-left: ${spacings.spacer * 1.5}px;
        padding-right: ${spacings.spacer * 1.5}px;
        padding-bottom: ${spacings.spacer * 1.5}px;

        & > * {
            flex: 1 1 50%;
            max-width: 50%;
        }

        & > * + * {
            margin-left: ${spacings.spacer * 2}px;
        }
    }
`;

// const Close = styled(CloseButton)`
//     position: absolute;
//     top: ${spacings.nudge}px;
//     right: ${spacings.nudge}px;
// `;

const Article = styled.article`
    display: block;
    text-align: left;

    & > * + * {
        margin-top: ${spacings.spacer}px;
    }

    @media ${mq.semilarge} {
        margin-bottom: 0;

        & > *:not(:first-child):last-child {
            margin-top: ${spacings.spacer * 2.5}px;
        }

        & > * + * {
            margin-top: ${spacings.spacer * 1.5}px;
        }
    }
`;

const Actions = styled.div`
    display: flex;
    width: 100%;

    & > * {
        flex: 0 1 50%;
        max-width: 50%;
        min-width: 0 !important;
    }

    & > * + * {
        margin-left: ${spacings.nudge * 3}px;
    }
`;

const MobileImage = styled.div`
    display: block;
    margin-top: ${spacings.nudge * 3}px;

    @media ${mq.semilarge} {
        display: none;
    }
`;

const DesktopImage = styled.div`
    display: none;

    @media ${mq.semilarge} {
        display: block;
    }
`;

const CallToActionModal: FC<{
    title?: string;
    titleAs?: HeadingTag;
    text?: string;
    image?: ImageProps;
    actionPrimary?: React.ReactNode;
    actionSecondary?: React.ReactNode;
    className?: string;
}> = ({
    title,
    titleAs,
    text,
    image,
    actionPrimary,
    actionSecondary,
    className,
}) => {
    return (
        <View className={className}>
            <Article>
                {title && <Heading as={titleAs} innerHTML={title} />}
                {text && <Copy innerHTML={text} />}
                {image && (
                    <MobileImage>
                        <Image coverSpace {...image} />
                    </MobileImage>
                )}
                <Actions>
                    {actionPrimary && actionPrimary}{' '}
                    {actionSecondary && actionSecondary}
                </Actions>
            </Article>
            {image && (
                <DesktopImage>
                    <Image coverSpace {...image} />
                </DesktopImage>
            )}
            {/* <Close onClick={onClose} /> */}
        </View>
    );
};

export default CallToActionModal;
