/* eslint-disable no-prototype-builtins */
import { useEffect } from 'react';

const httpGetAsync = (url: string, callback: (resp: any) => void) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
            callback(xmlHttp.responseText);
    };
    xmlHttp.open('GET', url, true);
    xmlHttp.send(null);
};

const uuidv4 = (): string => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const myCrypto = window['crypto'] || window['msCrypto'];

    return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c: any) =>
        (
            c ^
            (myCrypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
};

const setCookie = (e: string, o: string, i: number) => {
    let n = '';
    if (i) {
        const t = new Date();
        t.setTime(t.getTime() + 24 * i * 60 * 60 * 1e3),
            (n = '; expires=' + t.toUTCString());
    }
    document.cookie = e + '=' + o + n + '; path=/';
};

const getCookie = (name: string) => {
    const b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    let cookieValue = b ? b.pop() : '';
    cookieValue = cookieValue ? decodeURIComponent(cookieValue) : '';

    return cookieValue;
};

const serialize = (obj: { [key: string]: any }): string => {
    const str = [];
    for (const p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    return str.join('&');
};

export const useStats = () => {
    useEffect(() => {
        let uuid = getCookie('sid');
        if (!uuid) {
            uuid = uuidv4();
            setCookie('sid', uuid, 365);
        }

        const shareStats = () => {
            const sitedata = {
                uuid: uuid,
                useragent: navigator.userAgent,
                title: document.title,
                url:
                    window.location.origin +
                    window.location.pathname +
                    window.location.search,
                referrer: document.referrer,
            };
            const query = serialize(sitedata);

            httpGetAsync(
                'https://www.leitwerk-consulting.com/api/stats?' + query,
                (resp: any) => {
                    if (resp != 200) console.log('Stats could not be shared');
                }
            );
        };

        if (window) window.addEventListener('popstate', shareStats);
        // Router.events.on('routeChangeComplete', shareStats);
        shareStats();

        return () => {
            if (window) window.removeEventListener('popstate', shareStats);
            // Router.events.off('routeChangeComplete', shareStats);
        };
    }, []);
};
