import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { colors, mq, spacings, withRange } from 'utils/styles';
import Counter from 'components/blocks/Counter';
import PlayButton from 'components/buttons/PlayButton';
// import { animationInterval } from 'utils/animationInterval';
import { msToMinutesAndSeconds } from 'utils/formatting';
import Layout from 'components/base/Layout';
import Logo from 'components/base/icons/Logo';
import stepAnimation from './assets/leitw_circle_grey_section.json';
import finalAnimation from './assets/leitw_circle_red_end.json';
import ProgressBackground from 'components/blocks/ProgressBackground';
import ResetButton from 'components/buttons/ResetButton';
import VolumeSlider from 'components/blocks/VolumeSlider';
import TimeSelect from 'components/blocks/TimeSelect';
import CallToActionModal from 'components/blocks/CallToActionModal';
import Button from 'components/buttons/Button';
import Callout from 'components/typography/Callout';
import Prismic from '@prismicio/client';
import { resolveUnknownLink, TimeboxPage } from 'utils/prismic';
import { RichText } from 'prismic-dom';

import ApiSearchResponse from '@prismicio/client/types/ApiSearchResponse';
import StepPicker from 'components/blocks/StepPicker';
import { useLottie } from 'utils/useLottie';
import { useAudio } from 'utils/useAudio';
import Reset from 'components/base/icons/Reset';
import ButtonGhost from 'components/buttons/ButtonGhost';

// Import web worker
import worker, { Workerized } from 'workerize-loader!./utils/worker';
import * as TimerWorker from './utils/worker';
import { WorkerData } from './utils/worker';
import Popup from 'components/blocks/Popup';
import Link from 'components/typography/Link';

import stepSound from './assets/step_sound.mp3';
import finalSound from './assets/final_sound.mp3';
import { useStats } from 'utils/useStats';

const LogoLink = styled(Link)`
    display: block;
    padding-left: ${spacings.nudge * 3}px;
`;

const Main = styled(Layout.Main)<{ canScroll?: boolean }>`
    overflow: ${({ canScroll }) => canScroll && 'auto'};

    /* @media ${mq.medium} {
        justify-content: flex-start;
    } */

    @media (min-height: 45em) and (min-width: 40em) {
        justify-content: flex-start;
    }
`;

const DesktopStepPicker = styled(StepPicker)`
    display: none;

    @media ${mq.medium} {
        display: inline-flex;
    }
`;

const PickerWrapper = styled.div`
    display: none;

    @media ${mq.medium} {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
`;

const StyledPopup = styled(Popup)<{ isVisible?: boolean }>`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: none;

    transition: opacity 0.3s ease-in-out;
`;

const MobileStepPicker = styled(StepPicker)`
    display: inline-flex;
    ${withRange([spacings.spacer, spacings.spacer * 2], 'margin-top')}

    @media ${mq.medium} {
        display: none;
    }
`;

const StyledTimeSelect = styled(TimeSelect)<{ isVisible?: boolean }>`
    display: flex;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

    @media (min-width: 40em) {
        opacity: 1;
        display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    }

    @media (min-height: 35em) {
        display: flex;
        opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    }

    pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};

    transition: opacity 0.2s ease-in-out;
`;

const StyledCounter = styled(Counter)`
    ${withRange([spacings.nudge * 2, spacings.spacer * 3], 'margin-bottom')}
`;

const PlayWrapper = styled.div`
    position: relative;
`;

const StepAnimation = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    pointer-events: none;
`;

const FinalAnimation = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 150vh;
    width: 150vw;
    max-height: 150vh;
    max-width: 150vw;
    pointer-events: none;
`;

const Volume = styled(VolumeSlider)`
    max-width: 150px;
    width: 100%;
`;

const TimerWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    @media (min-height: 45em) and (min-width: 40em) {
        position: fixed;
        top: 50%;
        transform: translateY(calc(-50% - 90px));
        margin-top: 0;
    }
`;

const SectionTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    @media (min-width: 40em) {
        flex-direction: row;

        & > * + * {
            padding-left: ${spacings.spacer * 2}px;
            margin-left: ${spacings.spacer}px;
            transform: translateY(-10%);
        }

        & > * + *:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            bottom: 0;
            display: block;
            height: 100px;
            border-left: 2px solid ${colors.mono.medium};

            transform: translateY(-50%);
        }
    }

    @media (min-height: 35em) {
        flex-direction: column;

        & > * + * {
            padding-left: 0;
            margin-left: 0;
            transform: translateY(0);
        }

        & > * + *:before {
            content: none;
        }
    }
`;

const CounterSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
`;

const ModalWrapper = styled.div`
    margin: 0 auto;
    text-align: center;

    height: 100%;

    & > * + * {
        ${withRange([spacings.nudge, spacings.spacer], 'margin-top')}
    }

    @media (min-width: 52em) and (min-height: 35em) {
        padding: ${spacings.spacer}px 0;

        & > * + * {
            ${withRange([spacings.spacer, spacings.spacer * 3], 'margin-top')}
        }

        & > *:first-child {
            animation: show 1s;
        }

        & > * + * {
            animation: delayedShow 1s;
        }
    }

    @keyframes show {
        from {
            opacity: 0;
            transform: translateY(200px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes delayedShow {
        0% {
            opacity: 0;
            transform: translateY(200px);
        }
        20% {
            opacity: 0;
            transform: translateY(200px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

const SuperTitle = styled(Callout)`
    display: none;

    @media (min-width: 52em) and (min-height: 35em) {
        display: block;
        margin-top: ${spacings.spacer * 2}px;
        margin-bottom: ${spacings.spacer * 2}px;
    }
`;

type ViewState = 'timer' | 'modal';

interface MappedTimebox {
    superTitle: string;
    title: string;
    text: string;
    action: {
        href: string;
        label: string;
    };
    image: {
        small: string;
        semilarge: string | undefined;
        alt: string | undefined;
    };
}

function createRandomTimeboxContainer(timeboxes: TimeboxPage[]) {
    const randomTimeboxDataIndex = Math.floor(Math.random() * timeboxes.length);

    const { data } = timeboxes[randomTimeboxDataIndex];

    const timeboxData = {
        superTitle: RichText.asText(data.title) || '',
        title: RichText.asText(data.text_title) || '',
        text: RichText.asHtml(data.text) || '',
        action: {
            href: resolveUnknownLink(data.cta_link) || '',
            label: data.cta_label || '',
        },
        image: {
            small: data.image?.url || '',
            semilarge: data.image?.semilarge?.url || '',
            alt: data.image?.alt,
        },
    };

    return timeboxData;
}

const Page: FC = () => {
    useStats();
    const [viewState, setViewState] = useState<ViewState>('timer');
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [isPaused, setIsPaused] = useState<boolean>(false);
    const [duration, setDuration] = useState<number | null>(60000);
    const [counterVal, setCounterVal] = useState<string>('0100');
    const [animStart, setAnimStart] = useState<number>(0);
    const [steps, setSteps] = useState<string>('1');
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [timeboxData, setTimeboxData] = useState<MappedTimebox | null>(null);
    const [popupVisible, setPopupVisibilty] = useState<boolean>(false);
    const workerRef = useRef<Workerized<typeof TimerWorker> | null>(null);

    const { initAudio, playAudio, setVolume, volume } = useAudio();

    // const stepAnimContainer = useRef<HTMLDivElement>(null);
    const stepLottie = useLottie({ animData: stepAnimation });
    const finalLottie = useLottie({
        animData: finalAnimation,
        loop: 1,
    });
    if (stepLottie.animation) stepLottie.animation.playSpeed = 0.8;

    useEffect(() => {
        async function fetchTimeboxData() {
            const apiEndpoint = 'https://leitwerk-web.cdn.prismic.io/api/v2';
            const accessToken =
                'MC5ZTnhwb3hFQUFOUWFiMWti.LmZ177-977-977-977-977-977-977-977-9JO-_vRfvv73vv71U77-9eD_vv70t77-9Vu-_ve-_ve-_vWDvv71MM--_vQ';
            const client = Prismic.client(apiEndpoint, { accessToken });

            const timeboxDocuments: ApiSearchResponse = await client.query(
                [Prismic.Predicates.at('document.type', 'timebox')],
                { pageSize: '200' }
            );

            if (timeboxDocuments) {
                const { results }: { results: TimeboxPage[] } =
                    timeboxDocuments;
                const data = createRandomTimeboxContainer(results);

                if (data) {
                    setTimeboxData(data);
                }
            }
        }
        fetchTimeboxData();
    }, []);

    // useEffect(() => {
    //     console.log('STATE', timeboxData);
    // }, [timeboxData]);

    const handlePlayClick = () => {
        initAudio();
        if (isRunning) {
            workerRef.current?.postMessage(isPaused ? 'resume' : 'pause');
        } else {
            setIsRunning(true);
        }
    };

    const handleChange = ({
        minutes,
        seconds,
    }: {
        minutes: number;
        seconds: number;
    }) => {
        if (minutes === 0 && seconds === 0) setDuration(0);
        else setDuration(minutes * 60000 + seconds * 1000);
    };

    const reset = () => {
        setIsRunning(false);
        finalLottie?.animation?.removeEventListener(
            'complete',
            handleFinalAnimCompletion
        );

        // reset all states
        setCurrentStep(0);
        setAnimStart(0);
        stepLottie?.animation?.stop();
        finalLottie?.animation?.stop();

        setDuration(0);
        setCounterVal('-');
        setViewState('timer');
    };

    const restart = () => {
        setIsRunning(false);
        finalLottie?.animation?.removeEventListener(
            'complete',
            handleFinalAnimCompletion
        );

        // reset all states
        setCurrentStep(0);
        setAnimStart(0);
        stepLottie?.animation?.stop();
        finalLottie?.animation?.stop();

        // set counter back to last duration time
        if (duration) setCounterVal(msToMinutesAndSeconds(duration));
        setViewState('timer');
    };

    useEffect(() => {
        const handleWorkerMsg = (e: any) => {
            const data = e.data as WorkerData;
            if (data.isFinished) {
                setIsPaused(false);
                setCounterVal(data.formattedString);
                setCurrentStep(data.currentStep);
                finalLottie?.animation?.addEventListener(
                    'complete',
                    handleFinalAnimCompletion
                );
            } else {
                if (data.requested) {
                    setAnimStart(data.progress);
                }
                setIsPaused(data.isPaused);
                setCurrentStep(data.currentStep);
                setCounterVal(data.formattedString);
            }
        };

        if (duration && isRunning) {
            // reset all states
            setCurrentStep(0);
            setAnimStart(0);
            setIsPaused(false);
            stepLottie?.animation?.stop();
            finalLottie?.animation?.stop();

            workerRef.current = worker<typeof TimerWorker>();
            workerRef.current.startTimer(200, duration, parseInt(steps));
            workerRef.current.addEventListener(
                'message',
                handleWorkerMsg,
                false
            );
            workerRef.current?.postMessage('data');

            const handleFocus = () => {
                // on window focus request current data from worker thread
                workerRef.current?.postMessage('data');
            };
            window.addEventListener('focus', handleFocus);

            return () => {
                workerRef.current?.removeEventListener(
                    'message',
                    handleWorkerMsg,
                    false
                );
                workerRef.current?.terminate();
                setIsRunning(false);
                window.removeEventListener('focus', handleFocus);
            };
        }
    }, [
        duration,
        finalLottie?.animation,
        isRunning,
        stepLottie?.animation,
        steps,
    ]);

    const handleFinalAnimCompletion = () => {
        setIsRunning(false);
        setViewState('modal');
    };

    useEffect(() => {
        const onAnimLoop = () => {
            playAudio(finalSound)?.catch((err) => {
                console.log(err);
            });
        };

        if (!isRunning) return;
        if (currentStep > 1 && currentStep <= parseInt(steps)) {
            playAudio(stepSound)?.catch((err) => {
                console.log(err);
            });
            finalLottie?.animation?.stop();
            stepLottie?.animation?.goToAndPlay(0);
        } else if (currentStep === parseInt(steps) + 1) {
            playAudio(finalSound)?.catch((err) => {
                console.log(err);
            });
            finalLottie?.animation?.addEventListener(
                'loopComplete',
                onAnimLoop
            );
            stepLottie?.animation?.stop();
            finalLottie?.animation?.goToAndPlay(0);
        }

        return () => {
            finalLottie?.animation?.removeEventListener(
                'loopComplete',
                onAnimLoop
            );
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentStep,
        isRunning,
        steps,
        stepLottie?.animation,
        finalLottie?.animation,
    ]);

    useEffect(() => {
        const handleResize = () => {
            const vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <ProgressBackground
                duration={
                    duration ? (duration - duration * animStart) / 1000 : 0
                }
                steps={parseInt(steps)}
                start={viewState === 'modal' ? 1 : animStart}
                goal={
                    viewState === 'modal'
                        ? 1
                        : isRunning
                        ? isPaused
                            ? animStart
                            : 1
                        : undefined
                }
                showSteps={parseInt(steps) > 0}
            />
            <Layout.View>
                <Layout.Header>
                    <LogoLink
                        href="https://www.leitwerk-consulting.com/"
                        isExternal
                    >
                        <Logo />
                    </LogoLink>
                    {viewState === 'timer' && (
                        <PickerWrapper
                            onMouseEnter={() =>
                                !isRunning && setPopupVisibilty(true)
                            }
                            onMouseLeave={() =>
                                !isRunning && setPopupVisibilty(false)
                            }
                        >
                            <DesktopStepPicker
                                mode={isRunning ? 'show' : 'edit'}
                                activeStep={currentStep}
                                steps={parseInt(steps)}
                                maxSteps={6}
                                onChange={(value) =>
                                    value && setSteps(value.toString())
                                }
                            />
                            <StyledPopup
                                isVisible={popupVisible}
                                title="Abschnitte"
                                text="Du kannst deinen Timer in bis zu 6 Abschnitte unterteilen. Ein audiovisuelles Signal kennzeichnet dann jeden abgelaufenen Abschnitt."
                            />
                        </PickerWrapper>
                    )}
                </Layout.Header>
                <Main canScroll={viewState === 'modal'}>
                    {viewState === 'timer' ? (
                        <TimerWrapper>
                            <SectionTop>
                                <CounterSection>
                                    <StyledTimeSelect
                                        isVisible={!isRunning}
                                        presets={[
                                            '01',
                                            '05',
                                            '10',
                                            '15',
                                            '30',
                                            '60',
                                        ]}
                                        onClick={(_, value) => {
                                            const minutes = parseInt(value);
                                            setDuration(minutes * 60000);
                                            setCounterVal(`${value}00`);
                                        }}
                                    />
                                    <StyledCounter
                                        value={counterVal}
                                        blockHover={isRunning}
                                        onChange={handleChange}
                                    />
                                </CounterSection>
                                <PlayWrapper>
                                    <StepAnimation
                                        ref={stepLottie?.setContainer}
                                    />
                                    <FinalAnimation
                                        ref={finalLottie?.setContainer}
                                    />
                                    <PlayButton
                                        mode={
                                            isRunning && !isPaused
                                                ? 'pause'
                                                : 'play'
                                        }
                                        isDisabled={!duration}
                                        onClick={handlePlayClick}
                                    />
                                </PlayWrapper>
                            </SectionTop>
                            {viewState === 'timer' && (
                                <MobileStepPicker
                                    mode={isRunning ? 'show' : 'edit'}
                                    activeStep={currentStep}
                                    steps={parseInt(steps)}
                                    maxSteps={6}
                                    onChange={(value) =>
                                        value && setSteps(value.toString())
                                    }
                                />
                            )}
                        </TimerWrapper>
                    ) : (
                        <ModalWrapper>
                            <SuperTitle isInverted>
                                {timeboxData?.superTitle || 'Well done!'}
                            </SuperTitle>
                            <CallToActionModal
                                title={timeboxData?.title || ''}
                                text={timeboxData?.text || ''}
                                actionPrimary={
                                    <Button.View
                                        as="a"
                                        href={timeboxData?.action.href || ''}
                                    >
                                        <Button.Label>
                                            {timeboxData?.action.label ||
                                                'Zur Webseite'}
                                        </Button.Label>
                                    </Button.View>
                                }
                                actionSecondary={
                                    <ButtonGhost.View
                                        as="a"
                                        onClick={() => restart()}
                                    >
                                        <ButtonGhost.Icon>
                                            <Reset />
                                        </ButtonGhost.Icon>
                                        <ButtonGhost.Label>
                                            Neustart
                                        </ButtonGhost.Label>
                                    </ButtonGhost.View>
                                }
                                image={{
                                    small: timeboxData?.image?.small || '',
                                    semilarge:
                                        timeboxData?.image?.semilarge || '',
                                }}
                                // onClose={() => restart()}
                            />
                        </ModalWrapper>
                    )}
                </Main>
                {viewState === 'timer' && (
                    <Layout.Footer>
                        <ResetButton
                            onClick={() => (isRunning ? restart() : reset())}
                        />
                        <Volume
                            volume={volume}
                            onChange={(value) => setVolume(value / 100)}
                        />
                    </Layout.Footer>
                )}
            </Layout.View>
        </>
    );
};

export default Page;
