import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getCaretPosition, setCaretPosition } from 'utils/fieldCalculations';
import { isValidInteger } from 'utils/formatting';
import { hexToRgba } from 'utils/hexRgbConverter';
import { colors, fonts, spacings } from 'utils/styles';

const ValuesWrapper = styled.div<{ blockHover?: boolean }>`
    position: relative;
    display: flex;

    font-weight: 300;
    font-size: 96px;
    font-family: ${fonts.mono};
    line-height: 1.2;
    pointer-events: ${({ blockHover }) => (blockHover ? 'none' : 'all')};
    padding: 0 ${spacings.nudge * 1.5}px;

    & > * {
        color: ${colors.mono.medium};

        transition: color 0.2s ease-in-out;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 5px;
        bottom: 5px;
        left: 5px;
        height: 100px;
        pointer-events: none;
        opacity: 0;
        z-index: -1;

        transition: opacity 0.2s ease-in-out;

        border: solid 2px ${colors.mono.light};
        background: ${hexToRgba(colors.mono.light, 0.25)};
        border-radius: 6px;
    }

    &:hover {
        & > * {
            color: ${({ blockHover }) => !blockHover && colors.mono.dark};
        }

        &:before {
            opacity: ${({ blockHover }) => (blockHover ? 0 : 1)};
        }
    }
`;

const Seperator = styled.span`
    margin-left: -10px;
    margin-right: -10px;
    pointer-events: none;

    font-family: ${fonts.mono};
    font-weight: 300;
    font-size: 96px;
    // line-height: 1.4;
    color: ${colors.mono.medium};

    transition: color 0.2s ease-in-out;
`;

const ValueInput = styled.input`
    display: block;
    flex: 1 0 130px;
    max-width: 130px;

    font-weight: 300;
    font-size: 96px;
    font-family: ${fonts.mono};
    // color: ${colors.mono.dark};

    margin: 0;
    padding: 0 ${spacings.nudge}px;

    border: none;
    outline: none;
    background: none;
    -webkit-appearance: none;

    &::placeholder {
        color: ${colors.mono.medium};
    }
`;

const MinutesInput = styled(ValueInput)`
    // text-align: right;
    padding-right: ${spacings.nudge}px;
`;

const SecondsInput = styled(ValueInput)``;

interface Props {
    value?: string;
    onChange?: (value: { minutes: number; seconds: number }) => void;
    blockHover?: boolean;
    className?: string;
}

const toDoubleDigit = (value: number | string) => {
    let parsedValue: number;
    if (typeof value !== 'number') parsedValue = parseInt(value as string);
    else parsedValue = value as number;

    if (isNaN(parsedValue)) return '';
    else
        return parsedValue.toLocaleString(undefined, {
            minimumIntegerDigits: 2,
        });
};

const Counter: FC<Props> = ({ value, onChange, blockHover, className }) => {
    const [mode, setMode] = useState<'edit' | 'show'>('show');

    const minutesRef = useRef<HTMLInputElement | null>(null);
    const secondsRef = useRef<HTMLInputElement | null>(null);
    const [minutes, setMinutes] = useState<string>(
        value?.substring(0, 2) || '00'
    );
    const [seconds, setSeconds] = useState<string>(
        value?.substring(2, 4) || '00'
    );

    useEffect(() => {
        if (value === '-') {
            setMinutes('00');
            setSeconds('00');
            return;
        }
        const minutes = value?.substring(0, 2);
        const seconds = value?.substring(2, 4);

        // switch to write mode if value has been changed from outside
        setMode('show');
        if (minutes) {
            setMinutes(toDoubleDigit(minutes));
        }
        if (seconds) {
            setSeconds(toDoubleDigit(seconds));
        }
    }, [value]);

    useEffect(() => {
        if (mode === 'edit') {
            onChange &&
                onChange({
                    minutes: parseInt(minutes) || 0,
                    seconds: parseInt(seconds) || 0,
                });
        }
    }, [minutes, mode, onChange, seconds]);

    const handleMinutesChange = (
        ev: React.SyntheticEvent<HTMLInputElement>
    ) => {
        setMode('edit');

        const value = ev.currentTarget.value;
        const isValid = isValidInteger(value) || value === '';
        if (value.length <= 2 && isValid) setMinutes(value);
        if (value.length >= 2) {
            // secondsRef.current?.focus();
            if (secondsRef.current) {
                if (value.length >= 3 && seconds.length === 0) {
                    const lastChar = value.slice(2, 3);
                    if (isValidInteger(lastChar)) setSeconds(lastChar);
                }
                secondsRef.current?.focus();
                secondsRef.current?.select();
            }
        }
    };

    const handleSecondsChange = (
        ev: React.SyntheticEvent<HTMLInputElement>
    ) => {
        setMode('edit');

        const value = ev.currentTarget.value;
        const isValid = isValidInteger(value) || value === '';
        if (value.length <= 2 && isValid) setSeconds(value);
        if (value.length < 1) {
            // minutesRef.current?.focus();
            setCaretPosition(
                minutesRef.current?.value.length || 0,
                minutesRef.current
            );
        }
    };

    const handleKeyUp = (ev: React.KeyboardEvent) => {
        const value = (ev.currentTarget as HTMLInputElement).value;

        if (minutesRef.current === ev.currentTarget) {
            const caretPos = getCaretPosition(minutesRef.current);

            if (
                (ev.key === ' ' ||
                    ev.key === 'Spacebar' ||
                    ev.key === 'ArrowRight') &&
                (caretPos === 2 || value.length === 0)
            ) {
                ev.preventDefault();
                secondsRef.current?.focus();
                secondsRef.current?.select();
            }
        } else if (secondsRef.current === ev.currentTarget) {
            const caretPos = getCaretPosition(secondsRef.current);

            if (
                (ev.key === 'Backspace' || ev.key === 'ArrowLeft') &&
                caretPos === 0
            ) {
                ev.preventDefault();
                setCaretPosition(
                    minutesRef.current?.value.length || 0,
                    minutesRef.current
                );
            }
        }
    };

    return (
        <ValuesWrapper blockHover={blockHover} className={className}>
            <MinutesInput
                type="text"
                ref={minutesRef}
                value={minutes}
                max="2"
                onChange={handleMinutesChange}
                onKeyDown={handleKeyUp}
                placeholder="00"
                inputMode="numeric"
                maxLength={3}
            />
            <Seperator>:</Seperator>
            <SecondsInput
                type="text"
                ref={secondsRef}
                value={seconds}
                max="2"
                onChange={handleSecondsChange}
                onKeyDown={handleKeyUp}
                inputMode="numeric"
                placeholder="00"
                maxLength={2}
            />
        </ValuesWrapper>
    );
};

Counter.displayName = 'Counter';

export default Counter;
