import Reset from 'components/base/icons/Reset';
import React, { FC } from 'react';
import styled from 'styled-components';
import { colors, mq } from 'utils/styles';

const View = styled.button<{
    disable?: boolean;
    highlight?: boolean;
}>`
    width: 36px;
    height: 36px;

    display: inline-block;
    display: inline-flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    color: ${({ highlight }) =>
        highlight ? colors.white : colors.mono.medium};
    background-color: transparent;
    text-align: center;
    text-decoration: none;
    line-height: 1;

    box-sizing: border-box;
    outline: none;
    border: none;
    border-radius: 50%;
    user-select: none;
    opacity: 1;
    cursor: ${({ disable }) => (disable ? 'default' : 'pointer')};

    pointer-events: ${({ disable }) => (disable ? 'none' : 'all')};
    will-change: transform;

    text-align: center;

    transition: all ease-in-out 0.2s;

    &:before {
        content: '';
        position: absolute;
        top: -6px;
        left: -5px;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background: ${colors.primary};
        z-index: -1;

        transform: scale(${({ highlight }) => (highlight ? 1 : 0)});
        transform-origin: center center;
        transition: transform 0.4s ease-in-out;

        @media ${mq.semilarge} {
            top: -46px;
            left: -45px;
            width: 128px;
            height: 128px;
            border-radius: 64px;
        }
    }

    &:hover {
        // color: ${colors.mono.dark};
        opacity: 0.5;
    }

    &:focus {
        color: ${({ highlight }) => !highlight && colors.primary};
    }

    &:active {
        color: ${colors.primary};
    }
`;

const ResetButton: FC<{
    isDisabled?: boolean;
    highlight?: boolean;
    onClick?: (ev: React.SyntheticEvent<HTMLButtonElement>) => void;
    clasName?: string;
}> = ({ isDisabled, highlight, onClick, clasName }) => {
    return (
        <View
            disable={isDisabled}
            highlight={highlight}
            onClick={onClick}
            className={clasName}
        >
            <Reset />
        </View>
    );
};

export default ResetButton;
