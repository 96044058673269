import * as React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    display: block;
`;

const Reset: React.FC<{ iconColor?: string; className?: string }> = ({
    iconColor = 'currentColor',
    className,
}) => (
    <SVG
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M13.6498 2.35C12.1998.9 10.2098 0 7.99976 0c-4.41999 0-7.99 3.58-7.99 8s3.57001 8 7.99 8c3.73004 0 6.84004-2.55 7.73004-6h-2.08c-.82 2.33-3.04 4-5.65004 4-3.31 0-5.99999-2.69-5.99999-6s2.68999-6 5.99999-6c1.66001 0 3.14004.69 4.22004 1.78L8.99976 7h7.00004V0l-2.35 2.35z"
            fill={iconColor}
        />
    </SVG>
);

export default Reset;
