// check if value is a valid 4 point digit number
export const isValidInteger = (value: string, length?: number): boolean => {
    return length
        ? /^\d+$/gm.test(value) && value.length === length
        : /^\d+$/gm.test(value);
};

export const formatToInteger = (
    value: string,
    targetLength: number,
    fillChar = '0'
): string => {
    if (value.length > 0) {
        const valueLength = value.length;
        for (let i = 0; i < targetLength - valueLength; i++) {
            value += fillChar;
        }
    }
    return value;
};

export const msToMinutesAndSeconds = (ms: number): string => {
    const minutes = Math.floor(ms / 60000);
    const seconds = parseInt(((ms % 60000) / 1000).toFixed(0));

    const addLeadingZero = (value: number): string => {
        return value < 10 ? '0' + value : value.toString();
    };
    return (
        addLeadingZero(seconds === 60 ? minutes + 1 : minutes) +
        addLeadingZero(seconds === 60 ? 0 : seconds)
    );
};
