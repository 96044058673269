import React, { FC } from 'react';
import styled from 'styled-components';
import { mq, spacings } from 'utils/styles';

const View = styled.div`
    display: fixed;
    top: 0;
    left: 0;
    // height: 100vh;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    position: relative;
    max-width: ${spacings.wrapper}px;
    margin: 0 auto;
    overflow: hidden;
`;

const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0 ${spacings.nudge * 3}px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 80px auto min-content;
    grid-template-areas:
        'header'
        'main'
        'footer';

    @media ${mq.semilarge} {
        padding: 0 ${spacings.spacer * 1.5}px;
    }
`;

const Header = styled.div`
    grid-area: header;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${spacings.nudge * 3}px;
    margin-bottom: ${spacings.nudge * 2}px;

    @media ${mq.medium} {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }

    @media ${mq.semilarge} {
        margin-top: ${spacings.spacer * 1.5}px;
    }
`;

const Main = styled.div`
    grid-area: main;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Footer = styled.div`
    grid-area: footer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: ${spacings.nudge * 3}px;

    @media ${mq.semilarge} {
        margin-bottom: ${spacings.spacer * 1.5}px;
    }
`;

const Layout: FC = ({ children }) => {
    return (
        <View>
            <Content>{children}</Content>
        </View>
    );
};

export default {
    View: Layout,
    Header: Header,
    Main: Main,
    Footer: Footer,
};
