import * as React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    display: block;
`;

const LowVolume: React.FC<{
    iconColor?: string;
    className?: string;
}> = ({ iconColor = 'currentColor', className }) => {
    return (
        <SVG
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M0 5v6h4l5 5V0L4 5H0z" fill={iconColor} />
        </SVG>
    );
};

export default LowVolume;
