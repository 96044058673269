import Pause from 'components/base/icons/Pause';
import Play from 'components/base/icons/Play';
import React, { FC } from 'react';
import styled from 'styled-components';
import { colors, spacings } from 'utils/styles';

const StyledPlay = styled(Play)`
    margin-left: ${spacings.nudge * 2}px;
`;

const View = styled.button<{
    disable?: boolean;
}>`
    width: 144px;
    height: 144px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    text-align: center;
    text-decoration: none;
    line-height: 1;

    box-sizing: border-box;
    outline: none;
    border: none;
    border-radius: 72px;
    user-select: none;
    cursor: ${({ disable }) => (disable ? 'default' : 'pointer')};

    pointer-events: ${({ disable }) => (disable ? 'none' : 'all')};
    will-change: transform;

    background-color: ${colors.mono.light};
    color: ${({ disable }) =>
        disable ? colors.mono.medium : colors.mono.dark};
    text-align: center;

    transition: all ease-in-out 0.2s;

    & > * {
        transition: transform 0.2s ease-in-out;
    }

    &:hover {
        box-shadow: 0 4px 12px 0 rgba(72, 78, 87, 0.4);

        & > * {
            transform: scale(1.1);
        }
    }

    &:active {
        box-shadow: 0 4px 12px 0 rgba(72, 78, 87, 0);
    }
`;

export type PlayBtnMode = 'play' | 'pause';

const PlayButton: FC<{
    mode?: PlayBtnMode;
    isDisabled?: boolean;
    onClick?: (ev: React.SyntheticEvent<HTMLButtonElement>) => void;
    clasName?: string;
}> = ({ mode = 'play', isDisabled, onClick, clasName }) => {
    return (
        <View disable={isDisabled} onClick={onClick} className={clasName}>
            {mode === 'play' ? <StyledPlay /> : <Pause />}
        </View>
    );
};

export default PlayButton;
