export const setCaretPosition = (
    caretPos: number,
    elem?: HTMLInputElement | null
) => {
    if (elem) {
        elem.focus();
        elem.setSelectionRange(caretPos, caretPos);
    }
};

export const getCaretPosition = (elem?: HTMLInputElement | null) => {
    let iCaretPos = 0;

    if (elem && (elem.selectionStart || elem.selectionStart === 0)) {
        const newPos =
            elem.selectionDirection == 'backward'
                ? elem.selectionStart
                : elem.selectionEnd;
        if (newPos) iCaretPos = newPos;
    }
    return iCaretPos;
};
