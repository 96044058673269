import HighVolume from 'components/base/icons/HighVolume';
import LowVolume from 'components/base/icons/LowVolume';
import Muted from 'components/base/icons/Muted';
import Volume from 'components/base/icons/Volume';
import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'utils/styles';

const View = styled.button<{
    disable?: boolean;
}>`
    width: 36px;
    height: 36px;

    display: inline-block;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    color: ${colors.mono.medium};
    background-color: transparent;
    text-align: center;
    text-decoration: none;
    line-height: 1;

    box-sizing: border-box;
    outline: none;
    border: none;
    border-radius: 50%;
    user-select: none;
    cursor: ${({ disable }) => (disable ? 'default' : 'pointer')};
    opacity: 1;

    pointer-events: ${({ disable }) => (disable ? 'none' : 'all')};
    will-change: transform;

    text-align: center;

    transition: all ease-in-out 0.2s;

    &:hover {
        // color: ${colors.mono.dark};
        opacity: 0.5;
    }

    &:focus {
        color: ${colors.primary};
    }

    &:active {
        color: ${colors.primary};
    }
`;

const getIcon = (mode: VolumeBtnMode): React.ReactNode => {
    switch (mode) {
        case 'muted':
            return <Muted />;

        case 'low':
            return <LowVolume />;

        case 'high':
            return <HighVolume />;

        default:
        case 'medium':
            return <Volume />;
    }
};

export type VolumeBtnMode = 'muted' | 'low' | 'medium' | 'high';

const VolumeButton: FC<{
    mode?: VolumeBtnMode;
    isDisabled?: boolean;
    onClick?: (ev: React.SyntheticEvent<HTMLButtonElement>) => void;
    className?: string;
}> = ({ mode = 'medium', isDisabled, onClick, className }) => {
    return (
        <View disable={isDisabled} onClick={onClick} className={className}>
            {getIcon(mode)}
        </View>
    );
};

export default VolumeButton;
