import * as React from 'react';
import styled from 'styled-components';

import { colors, spacings, fonts } from 'utils/styles';

const View = styled.a<{
    inverted?: boolean;
    disable?: boolean;
}>`
    min-height: 3em;
    height: 3em;
    min-width: 190px;
    padding: 0.1em 1em;

    display: inline-block;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    text-align: center;
    text-decoration: none;
    line-height: 1;

    outline: none;
    border: none;
    user-select: none;
    cursor: pointer;

    pointer-events: ${({ disable }) => (disable ? 'none' : 'all')};

    will-change: transform;

    font-size: 17px;
    text-transform: uppercase;
    font-family: ${fonts.primary};
    font-weight: 700;
    letter-spacing: 0.05em;

    background-color: transparent;
    border: 2px solid
        ${({ inverted, disable }) =>
            disable
                ? colors.mono.medium
                : inverted
                ? colors.white
                : colors.mono.dark};
    text-align: center;

    transition: all ease-in-out 0.2s;

    & > * {
        color: ${({ inverted, disable }) =>
            disable
                ? colors.mono.medium
                : inverted
                ? colors.white
                : colors.mono.dark};
    }

    & > :not(:last-child) {
        padding-right: ${spacings.nudge}px;
    }

    &:hover {
        transform: scale(1.02);
    }

    &:focus {
        text-decoration: underline;
        transform: scale(1.012);
    }

    &:active {
        transform: scale(0.95);
    }
`;

interface Props {
    isSmall?: boolean;
    isDisabled?: boolean;
    isInverted?: boolean;
    className?: string;
}

type BtnProps = Props & {
    as?: 'button';
    onClick?: (ev: React.SyntheticEvent<HTMLButtonElement>) => void;
};

type LinkProps = Props & {
    as?: 'a';
    href?: string;
    isExternal?: boolean;
    onClick?: (ev: React.SyntheticEvent<HTMLAnchorElement>) => void;
};

const ButtonGhost: React.FC<BtnProps | LinkProps> = React.forwardRef(
    (
        {
            as,
            isDisabled,
            isInverted,
            isSmall,
            onClick,
            className,
            children,
            ...rest
        },
        ref
    ) => {
        if (as === 'button') {
            return (
                <View
                    ref={ref}
                    as={as as any}
                    disable={isDisabled}
                    inverted={isInverted}
                    isSmall={isSmall}
                    onClick={onClick}
                    className={className}
                    {...rest}
                >
                    {children}
                </View>
            );
        } else {
            return (
                <View
                    ref={ref}
                    as={as as any}
                    href={(rest as LinkProps).href}
                    target={(rest as LinkProps).isExternal ? '_blank' : ''}
                    rel={
                        (rest as LinkProps).isExternal
                            ? 'noopener noreferrer'
                            : ''
                    }
                    disable={isDisabled}
                    inverted={isInverted}
                    isSmall={isSmall}
                    onClick={onClick}
                    className={className}
                    {...rest}
                >
                    {children}
                </View>
            );
        }
    }
);

ButtonGhost.displayName = 'ButtonGhost';

const Label = styled.span`
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Icon = styled.span`
    padding: 0;
    margin-right: ${spacings.nudge * 2}px;
`;

export default { View: ButtonGhost, Label: Label, Icon: Icon };
