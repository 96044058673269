import { Document } from '@prismicio/client/types/documents';
import { Link as PrismicLink, RichText as PrismicRichText } from 'prismic-reactjs'


export interface TimeboxPage extends Document {
    data: TimeBoxData;
}

export interface TimeBoxData {
    title?: any,
    image?: PrismicImage,
    text_title?: any,
    text?: typeof PrismicRichText,
    cta_label?: string,
    cta_link?: PrismicLink;
}

export interface PrismicImage {
    url?: string;
    alt?: string;
    small?: {
        url?: string;
        alt?: string;
    };
    semilarge?: {
        url?: string;
        alt?: string;
    };

}

export type PrismicHeading = Array<{
    type:
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'heading4'
    | 'heading5'
    | 'heading6';
    text: string;
}>;



export const linkResolver = (doc: Document) => {
    if (doc.type === 'page' && doc.uid !== 'start') return `/${doc.uid}`;
    return `/`;
};

export const resolveUnknownLink = (link: unknown): string | null => {
    if (typeof link === 'object' && link !== null && 'link_type' in link) {
        const mylink = link as PrismicLink;
        if (mylink.link_type === 'Any') {
            return null;
        }
        if (mylink.link_type === 'Web') {
            return mylink.url || null;
        }
        if (mylink.link_type === 'Document') {
            return linkResolver(mylink as any);
        }
        if (mylink.link_type === 'Media') {
            return mylink.url || null;
        }
        return null;
    }
    return null;
};
