import React, { FC } from 'react';
import styled from 'styled-components';
import { colors, fonts, withRange } from 'utils/styles';

const View = styled.h1<{ isInverted?: boolean; textColor?: string }>`
    margin: 0;
    padding: 0;

    font-family: ${fonts.primary};
    font-weight: 700;
    font-style: normal;
    line-height: 1;
    text-transform: uppercase;
    ${withRange([50, 69], 'font-size')}
    color: ${({ textColor, isInverted }) =>
        textColor || (isInverted ? colors.white : colors.mono.dark)};

    hyphens: auto;
    overflow-wrap: break-word;
`;

export type CalloutTag =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'span'
    | 'div';

const Callout: FC<{
    as?: CalloutTag;
    isInverted?: boolean;
    textColor?: string;
    innerHTML?: string;

    className?: string;
}> = ({ as, isInverted, textColor, innerHTML, className, children }) => {
    return (
        <View
            as={as}
            isInverted={isInverted}
            textColor={textColor}
            dangerouslySetInnerHTML={
                innerHTML && !children ? { __html: innerHTML } : undefined
            }
            className={className}
        >
            {children}
        </View>
    );
};

export default Callout;
