import React from 'react';
import { createGlobalStyle } from 'styled-components';
import './fonts/oswald-v36-latin/oswald-v36-latin.css';
import './fonts/courier-prime-v2-latin/courier-prime-v2-latin.css';
import Page from './Page';

const GlobalStyle = createGlobalStyle`
     html {
        font-size: 100%;
        box-sizing: border-box;
    }

    body {
        width: 100%;
        margin: 0;
        color: hotpink;
        font-family: 'MS Comic Sans';
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
`;

function App() {
    return (
        <>
            <GlobalStyle />
            <Page />
        </>
    );
}

export default App;
