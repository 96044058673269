import * as React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    display: block;
`;

const Segments: React.FC<{ iconColor?: string; className?: string }> = ({
    iconColor = 'currentColor',
    className,
}) => (
    <SVG
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.99994 0v3.08C6.28925 3.51371 3.57857 5.93949 3.08095 9H.0498C.52411 4.26585 4.32491.4675 8.99994 0zM.0498 11c.47431 4.7342 4.27511 8.5325 8.95014 9v-3.08c-2.71069-.4337-5.42137-2.8595-5.91899-5.92H.0498zm19.9501-2h-3.03c-.43-3-2.97-5.49-5.97-5.92V0c5 .47 8.53 4 9 9zm-9 11v-3.08c3-.43 5.54-2.92 5.97-5.92h3.03c-.47 5-4 8.53-9 9z"
            fill={iconColor}
        />
    </SVG>
);

export default Segments;
