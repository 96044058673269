import React, { FC } from 'react';
import styled from 'styled-components';

import { colors, fonts, spacings } from 'utils/styles';

const View = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 230px;
    background: ${colors.white};
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    color: ${colors.mono.medium};

    padding: ${spacings.nudge * 2}px;
    margin-top: ${spacings.nudge * 3}px;

    & > * + * {
        margin-top: ${spacings.nudge}px;
    }
`;

const Triangle = styled.div`
    position: absolute;
    top: 0;
    right: 12px;
    transform: translateY(-100%);

    width: 25px;
    height: 25px;
    overflow: hidden;
    box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.25);

    &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background: ${colors.white};
        transform: rotate(45deg);
        top: 19px;
        left: 6px;
        box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.25);
    }
`;

const Title = styled.div`
    font-family: ${fonts.primary};
    font-weight: 700;
    font-size: 13px;
    line-height: 1.46;
`;

const Text = styled.div`
    font-family: ${fonts.secondary};
    font-weight: 400;
    font-size: 11px;
    line-height: 1.36;
`;

const Popup: FC<{
    title?: string;
    text?: string;
    className?: string;
}> = ({ title, text, className }) => {
    return (
        <View className={className}>
            <Triangle />
            {title && <Title>{title}</Title>}
            {text && <Text>{text}</Text>}
        </View>
    );
};

export default Popup;
