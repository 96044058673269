import React from 'react';
import styled, { css } from 'styled-components';
import { colors, fonts, mq, spacings, withRange } from 'utils/styles';

const View = styled.div<{
    isInverted?: boolean;
    textColor?: string;
    columns?: boolean;
}>`
    font-family: ${fonts.secondary};
    font-weight: 400;
    font-style: normal;
    ${withRange([15, 17], 'font-size')}
    line-height: 1.1;

    hyphens: auto;
    color: ${({ textColor, isInverted }) =>
        textColor || (isInverted ? colors.white : colors.mono.dark)};

    @media ${mq.medium} {
        hyphens: none;
    }

    a:not([class]) {
        color: currentColor;
        transition: color 0.25s;

        &:hover {
            opacity: 0.75;
        }
    }

    *:first-child {
        margin-top: 0;
    }

    // *:last-child {
    //     margin-bottom: 0;
    // }

    ${({ columns }) =>
        columns &&
        css`
            columns: 350px 2;
            column-gap: ${spacings.spacer}px;

            li {
                break-inside: avoid;
            }

            blockquote {
                break-inside: avoid;
            }
        `};
`;

const Copy: React.FC<{
    isInverted?: boolean;
    textColor?: string;
    columns?: boolean;
    innerHTML?: string;
    className?: string;
}> = ({
    isInverted,
    textColor,
    columns = false,
    innerHTML,
    className,
    children,
}) => {
    return (
        <View
            isInverted={isInverted}
            textColor={textColor}
            columns={columns}
            dangerouslySetInnerHTML={
                innerHTML && !children ? { __html: innerHTML } : undefined
            }
            className={className}
        >
            {children}
        </View>
    );
};

export default Copy;
