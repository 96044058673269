import VolumeButton, { VolumeBtnMode } from 'components/buttons/VolumeButton';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { hexToRgba } from 'utils/hexRgbConverter';
import { colors, mq, spacings } from 'utils/styles';

const View = styled.div`
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    & > * + * {
        margin-left: ${spacings.nudge * 2}px;
    }
`;

const SliderWrapper = styled.div`
    display: none;
    position: relative;
    width: 100%;

    @media ${mq.medium} {
        display: flex;
    }
`;

const Input = styled.input`
    background: none;

    &[type='range'] {
        -webkit-appearance: none;
        margin: 18px 0;
        width: 100%;
    }

    &[type='range']:focus {
        outline: none;
    }

    &[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        background: ${hexToRgba(colors.mono.medium, 0.25)};
        border-radius: 1px;
        border: 0.2px solid transparent;
    }

    &[type='range']::-webkit-slider-thumb {
        border: 1px solid transparent;
        border-radius: 12px;
        height: 12px;
        width: 12px;
        background: ${colors.mono.medium};
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -6px;
    }

    &[type='range']:focus::-webkit-slider-runnable-track {
        background: ${hexToRgba(colors.mono.medium, 0.25)};
    }

    &[type='range']::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        background: ${hexToRgba(colors.mono.medium, 0.25)};
        border-radius: 1px;
        border: 0.2px solid transparent;
    }

    &[type='range']::-moz-range-thumb {
        border: 1px solid transparent;
        border-radius: 12px;
        height: 12px;
        width: 12px;
        background: ${colors.mono.medium};
        cursor: pointer;
    }
`;

const Progress = styled.div<{ progressVal?: number }>`
    position: absolute;
    top: 50%;
    left: 0;
    width: ${({ progressVal }) => (progressVal ? progressVal : 0)}%;
    height: 2px;
    border: solid 1px transparent;
    border-radius: 1px;
    background: ${colors.mono.medium};
    margin-top: -1px;
    pointer-events: none;
`;

const getBtnMode = (volume: number): VolumeBtnMode => {
    switch (true) {
        case volume <= 0:
            return 'muted';

        case volume >= 40 && volume < 100:
            return 'medium';

        case volume >= 100:
            return 'high';

        default:
            return 'low';
    }
};

const VolumeSlider: FC<{
    /** Value in range [0-1] */
    volume?: number;
    onChange?: (volume: number) => void;
    className?: string;
}> = ({ volume = 0, onChange, className }) => {
    const [getVolume, setVolume] = useState<number>(volume * 100);
    const [lastVolume, setLastVolume] = useState<number>(volume * 100);
    const [btnMode, setBtnMode] = useState<VolumeBtnMode>(
        getBtnMode(volume * 100)
    );

    useEffect(() => {
        setVolume(volume * 100);
    }, [volume]);

    useEffect(() => {
        onChange && onChange(getVolume);
        setBtnMode(getBtnMode(getVolume));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getVolume]);

    return (
        <View className={className}>
            <SliderWrapper>
                <Progress progressVal={getVolume} />
                <Input
                    type="range"
                    min="0"
                    max="100"
                    step="0.01"
                    value={getVolume}
                    onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setVolume((prev) => {
                            setLastVolume(prev);
                            return parseInt(newValue);
                        });
                    }}
                />
            </SliderWrapper>
            <VolumeButton
                mode={btnMode}
                onClick={() => {
                    if (btnMode === 'muted') {
                        setVolume(lastVolume);
                    } else {
                        setVolume((prev) => {
                            setLastVolume(prev);
                            return 0;
                        });
                    }
                }}
            />
        </View>
    );
};

export default VolumeSlider;
