import { useEffect, useState } from 'react';
import Lottie, { AnimationItem } from 'lottie-web';

export const useLottie = (props: {
    animData: any;
    loop?: boolean | number;
    autoplay?: boolean;
}) => {
    const [container, setContainer] = useState<HTMLElement | null>(null);
    const [animation, setAnimation] = useState<AnimationItem | null>(null);

    useEffect(() => {
        if (container) {
            const newAnimation = Lottie.loadAnimation({
                container: container,
                renderer: 'svg',
                loop: props.loop || false,
                autoplay: props.autoplay || false,
                animationData: props.animData,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            });
            setAnimation(newAnimation);
        }
    }, [container, props.animData, props.autoplay, props.loop]);

    return {
        setContainer,
        animation,
    };
};
