import { useEffect, useState } from 'react';

export const useAudio = (initialVolume?: number) => {
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [volume, setVolume] = useState<number>(initialVolume || 1);

    const setSource = (src: string) => {
        if (audio) {
            audio.src = src;
        }
    };

    const playAudio = (src?: string): Promise<void> | undefined => {
        if (audio && src && audio.src !== src) audio.src = src;
        return audio?.play();
    };

    const initAudio = () => {
        if (!audio) {
            const audio = new Audio();
            audio.src =
                'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
            audio.play();
            setAudio(audio);
        }
    };

    useEffect(() => {
        if (audio && volume >= 0 && volume <= 1) audio.volume = volume;
    }, [audio, volume]);

    return {
        initAudio,
        setSource,
        playAudio,
        setVolume,
        volume,
    };
};
